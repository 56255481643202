export const format = {
  methods: {
    /**
     * 이메일 형식 검사
     */
    emailValidate(email) {
      // eslint-disable-next-line
      const exptext =/^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/;
      if (exptext.test(email) == false) {
        return false;
      }
    },
    /**
     * 비밀번호 형식검사
     */
    checkPassword(str) {
      let num = str.search(/[0-9]/g);
      let eng = str.search(/[a-z]/gi);
      let spe = str.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);

      if (str.length < 9 || str.length > 12) {
        return "비밀번호는 9자리 ~ 12자리 이내로 입력해주세요.";
      } else if (str.search(/₩s/) != -1) {
        return "비밀번호는 공백없이 입력해주세요.";
      } else if (num < 0 || eng < 0 || spe < 0) {
        return "비밀번호는 영문, 숫자, 특수문자를 혼합하여 입력해주세요.";
      } else {
        return "";
      }
    },
    /**
     * 비밀번호 연속된 문자 검사
     */
    pwContinue(str) {
      //연속된 문자, 숫자 체크(4자리)
      let cnt = 0;
      let cnt2 = 0;
      let tmp = "";
      let tmp2 = "";
      let tmp3 = "";
      let tmp4 = "";
      // validpw = document.frm01.pw.value;
      for (let i = 0; i < str.length; i++) {
        tmp = str.charAt(i);
        tmp2 = str.charAt(i + 1);
        tmp3 = str.charAt(i + 2);
        tmp4 = str.charAt(i + 3);

        if (
          tmp.charCodeAt(0) - tmp2.charCodeAt(0) == 1 &&
          tmp2.charCodeAt(0) - tmp3.charCodeAt(0) == 1 &&
          tmp3.charCodeAt(0) - tmp4.charCodeAt(0) == 1
        ) {
          cnt = cnt + 1;
        }
        if (
          tmp.charCodeAt(0) - tmp2.charCodeAt(0) == -1 &&
          tmp2.charCodeAt(0) - tmp3.charCodeAt(0) == -1 &&
          tmp3.charCodeAt(0) - tmp4.charCodeAt(0) == -1
        ) {
          cnt2 = cnt2 + 1;
        }
      }
      if (cnt > 0 || cnt2 > 0) {
        return "비밀번호에 4자리 이상 연속된 문자(예:abcd)는 사용할 수 없습니다.";
      } else {
        return "";
      }
    },
    /**
     * 비밀번호 동일한 숫자, 문자 검사
     */
    pwSame(str) {
      //동일 문자, 숫자 체크(3자리)
      var tmp = "";
      var cnt = 0;
      for (let i = 0; i < str.length; i++) {
        tmp = str.charAt(i);
        if (
          tmp == str.charAt(i + 1) &&
          tmp == str.charAt(i + 2) &&
          tmp == str.charAt(i + 3)
        ) {
          cnt = cnt + 1;
        }
      }
      if (cnt > 0) {
        return "비밀번호에 4자리 이상 연속된 동일한 문자(예:aaaa)는 사용할 수 없습니다.";
      } else {
        return false;
      }
    },
    /**
     * 비밀번호 특수 문자 검사
     */
    checkSpecial(str) {
      // eslint-disable-next-line
      const regExp = /[<>"']/g;
      if (regExp.test(str)) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * API 형식 검사
     */
    urlTest(url) {
      // eslint-disable-next-line
      let regex =/(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
      // eslint-disable-next-line
      // const regex = /https:\/\/(?:[\w-]+\.)+[\w-]+(?:\/[\w-./?%&=]*)?$/;
      // const regex = /^https?:\/\/(?:[\w-]+\.)+[\w-]+(?:\/[\w-./?%&=]*)?$/;
      return regex.test(url);
    },
    /**
     * 글자수 검증
     */
    validateLength(min, max, input) {
      const length = input.length;
      return length >= min && length <= max;
    },
    setLNB(visible, category) {
      let data = {
        visible: visible,
        category: category,
      };
      this.$store.dispatch("SET_LNB", data);
    },
  },
};
