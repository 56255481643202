<template>
  <div class="home">
    <div class="login">
      <div class="flex">
        <div class="loginBox">
          <h1>
            <router-link to="/">
              <img src="@/assets/images/logo.png" alt="" />
            </router-link>
          </h1>
          <div class="form">
            <p>아이디(이메일)</p>
            <input type="text" v-model="userId" @keyup.enter="submit" />
            <p>비밀번호</p>
            <input :type="type" v-model="password" @keyup.enter="submit" />
            <button class="point large" @click="submit">로그인</button>
            <div v-if="error" class="error">
              {{ errMessage }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <p class="copy">Copyright&copy; Raypann All Rights Resrved.</p>
  </div>
</template>
<script>
import { login } from "@/api/index";
import { mapState } from "vuex";
import { format } from "@/mixins/format";
import { setLNB } from "@/mixins/setLNB";
import { setGNB } from "@/mixins/setGNB";
export default {
  mixins: [format, setLNB, setGNB],
  mounted() {
    this.setLNB(false);
    this.setGNB(false);
  },
  computed: {
    ...mapState(["lnbVisible", "gnbVisible"]),
  },
  beforeDestroy() {
    this.setLNB(true);
    this.setGNB(true);
  },
  watch: {
    userId() {
      this.error = false;
      this.errMessage = "";
    },
    password() {
      this.error = false;
      this.errMessage = "";
    },
  },
  data() {
    return {
      type: "password",
      userId: "",
      password: "",
      error: false,
      errMessage: "",
    };
  },
  methods: {
    /**
     * 필요 페이지 정보 리셋 기능
     */
    reset() {
      this.password = "";
      this.error = false;
      this.errMessage = "";
    },
    /**
     * 로그인 기능
     */
    async submit() {
      if (this.userId == "") {
        this.error = true;
        this.errMessage = "아이디(이메일)과 비밀번호를 다시 확인해주세요";
        return;
      } else if (this.emailValidate(this.userId) == false) {
          this.error = "emailValidate";
          this.errMessage = "아이디(이메일)과 비밀번호를 다시 확인해주세요";
          return;
      } else if (this.userId.length > 32) {
        this.error = "emailValidate";
        this.errMessage = "아이디(이메일)과 비밀번호를 다시 확인해주세요";
        return;
      } else if (this.password == "") {
        this.error = true;
        this.errMessage = "아이디(이메일)과 비밀번호를 다시 확인해주세요";
        return;
      } else if (this.checkPassword(this.password) != "") {
        this.error = true;
        this.errMessage = "아이디(이메일)과 비밀번호를 다시 확인해주세요";
        return;
      } 
      // else if (this.checkSpecial(this.password) == true) {
      //   this.error = true;
      //   this.errMessage = "(<, >, ', \")를 제외한 특수문자를 입력해주세요.";
      //   return;
      // }
      let data = {
        userId: this.userId,
        password: this.password,
      };
      await login(data).then((res) => {
        if (res.data.status == 200) {
          this.$store.dispatch("LOGIN", res.data.accesstoken);
          document.location.href = "/home";
        } else if (res.data.status == 410) {
          this.error = true;
          this.errMessage = `해당 계정은 이미 다른 브라우저에서 로그인이 되어있습니다. 로그인되어 있는 브라우저에서 로그아웃 후 사용해주세요.`;
        } else {
          this.error = true;
          if (res.data.data) {
            this.errMessage = `로그인 시도를 5회 초과하셨습니다. 5분 후에 다시 시도해주세요.`;
          } else {
            this.errMessage = "아이디(이메일)과 비밀번호를 다시 확인해주세요";
          }
        }
      });
    },
  },
};
</script>
